/**
 * This is file for check are images json file or single image url
 * Create it because sometimes from backend we got string with url and sometimes got JSON witth array of images
 */

const checkIsAndParseJson = img => {
  let image;
  try {
    image = JSON.parse(img);
  } catch (e) {
    image = [];
    image.push(img);
  }
  return image;
};

export default checkIsAndParseJson;
